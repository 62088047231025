<template>
        <b-row>
            <b-col md="3" sm="12">
                <div class="card card-custom card-stretch gutter-b shadow-sm">
                    <div class="card-header border-0 ">
                        <h3 class="card-title">
                          <span class="card-label font-weight-bolder text-dark" style="display: flex; align-items: center;">
                            Estadisticas
                          </span>
                        </h3>
                    </div>
                    <div v-show="showSelect" style="padding-left: 20px; padding-right: 20px;">
                      <b-form-select
                        v-model="Eventselected" 
                        :options="EventsClient"
                        value-field="id"
                        text-field="name"
                        ></b-form-select>
                    </div>
                    <div class="card-body">
                          <b-row>
                            <b-col md="12" class="mb-5">
                              <span class="font-weight-bolder text-dark">
                                 <div class="mb-3">progreso : </div>
                                 <b-row>
                                   <b-col lg="12" md="12" sm="12">
                                     <b-progress max="100">
                                       <b-progress-bar :value="confirmed.percentage" :precision="2" show-progress style="background-color: #52DD26 !important;"></b-progress-bar>
                                       <b-progress-bar :value="unconfirmed.percentage" :precision="2" show-progress variant="danger"></b-progress-bar>
                                       <b-progress-bar :value="pending.percentage" :precision="2" show-progress variant="secondary" style="color: black !important;"></b-progress-bar>
                                     </b-progress>
                                   </b-col>
                                 </b-row>
                              </span>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col lg="12" md="12" sm="12">
                              <div class="card card-custom card-shadowless mb-2 mt-2" style="background-color: rgba(82, 221, 38, 0.1) !important;">
                                <!--begin::Body-->
                                <div class="card-body" style="padding: 15px;">
                                  <a
                                    href="#"
                                    class="font-weight-bolder text-hover-state-dark d-block"
                                    style="color: #52DD26 !important; "
                                    >Confirmados</a
                                  >
                                  <div class="font-weight-bold text-muted font-size-sm">
                                    <span class="text-dark-75 font-size-h2 font-weight-bolder mr-2">{{ confirmed.total }}</span>
                                  </div>
                                </div>
                                <!--end:: Body-->
                              </div>
                            </b-col>
                            <b-col lg="12" md="12" sm="12">
                              <div class="card card-custom bg-light-danger card-shadowless mb-2 mt-2">
                                <!--begin::Body-->
                                <div class="card-body" style="padding: 15px;">
                                  <a
                                    href="#"
                                    class="font-weight-bolder text-danger text-hover-state-dark d-block"
                                    >Inacistencias</a
                                  >
                                  <div class="font-weight-bold text-muted font-size-sm">
                                    <span class="text-dark-75 font-size-h2 font-weight-bolder mr-2">{{ unconfirmed.total }}</span>
                                  </div>
                                </div>
                                <!--end:: Body-->
                              </div>
                            </b-col>
                            <b-col lg="12" md="12" sm="12">
                              <div class="card card-custom card-shadowless mb-2 mt-2" style="background-color: #D6D6D6 !important;">
                                <!--begin::Body-->
                                <div class="card-body" style="padding: 15px;">
                                  <a
                                    href="#"
                                    class="font-weight-bolder text-hover-state-dark d-block"
                                    style="color: #7E7E7E !important;"
                                    >Sin Confirmar</a
                                  >
                                  <div class="font-weight-bold text-muted font-size-sm">
                                    <span class="text-dark-75 font-size-h2 font-weight-bolder mr-2">{{ pending.total }}</span>
                                  </div>
                                </div>
                                <!--end:: Body-->
                              </div>
                            </b-col>
                            <b-col lg="12" md="12" sm="12">
                              <div class="card card-custom bg-light-warning card-shadowless mb-2 mt-2">
                                <!--begin::Body-->
                                <div class="card-body" style="padding: 15px;">
                                  <a
                                    href="#"
                                    class="font-weight-bolder text-warning text-hover-state-dark d-block"
                                    >Numero de Invitados</a
                                  >
                                  <div class="font-weight-bold text-muted font-size-sm">
                                    <span class="text-dark-75 font-size-h2 font-weight-bolder mr-2">{{ totalguests }}</span>
                                  </div>
                                </div>
                                <!--end:: Body-->
                              </div>
                            </b-col>
                          </b-row>
                    </div>
                </div>
            </b-col>
            <b-col md="9" sm="12">
                <div class="card card-custom card-stretch gutter-b">
                    <div class="card-header border-0 py-5">
                      <h3 class="card-title">
                        <span class="card-label font-weight-bolder text-dark" style="display: flex; align-items: center;">
                          Invitados
                          <span class="text-muted font-weight-bold font-size-sm">
                             <b-form-select size="sm" v-model="perPage" :options="PageOptions" :clearable="false" class="ml-2" />
                          </span>
                        </span>
                      </h3>
                      <div class="card-toolbar">
                        <b-row>
                            <b-col md="6">
                                <b-form-input
                                  v-model="searchTerm"
                                  class="d-inline-block mr-1"
                                  placeholder="Busca a tu invitado"
                                  @input="handleSearch"
                                />
                            </b-col>
                            <b-col md="6">
                                <a class="btn btn-success font-weight-bolder font-size-sm" 
                                  v-b-toggle.add-guest-sidebar
                                  @click="Create()"
                                >
                                  <span class="svg-icon svg-icon-md svg-icon-white">
            
                                    <inline-svg src="media/svg/icons/Communication/Add-user.svg" />
                                    </span
                                  >Agregar invitado</a
                                >
                            </b-col>
                        </b-row>
                      </div>
                    </div>
                    <div class="card-body py-0">
                      <b-table
                        ref="tableInvitados"
                        :items="ArrayGuests"
                        :fields="fields"
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc"
                        :sort-direction="sortDirection"
                        responsive
                        @sort-changed="onSortChange"
                        style="position: relative !important; height: 500px !important;"
                      >
                        
                        <template #cell(name)="data">
                            <b-media vertical-align="center">
                              <template #aside>
                                <b-avatar
                                  class="border"
                                  size="32"
                                  button
                                  :class="GuestAssitance(data.item.assistance)"
                                />
                              </template>
                              <!-- @click="NavigateUserProfile(data.item.IdUser)" -->
                              <span class="font-weight-bold text-nowrap">
                                {{ data.value }}
                              </span>
                            </b-media>
                        </template>

                        <template #cell(phone)="data">
                          <span class="text-nowrap">
                            {{ data.value }}
                          </span>
                        </template>

                        <template #cell(assistance)="data">
                          <span class="text-nowrap">
                            {{ data.value == 1 ? 'Si' : data.value == 0 ? 'No' : 'Sin Confimar' }}
                          </span>
                        </template>

                        <template #cell(Actions)="data">
                          <span class="text-nowrap">
                            <a
                              target="_blank"
                              v-bind:href="`https://api.whatsapp.com/send?phone=+${data.item.phone}&text=Hola%20${data.item.name}%20%0ATe%20quiero%20Invitar%20a%20mi%20fiesta.%0Aporfavor%20confirma%20tu%20asistencia%20en%20esta%20direccion%3A%0A%0Ahttps%3A%2F%2Fbeta-client.invination.mx%2F${eventSlug}%2F${data.item.code}`"
                              class="btn btn-icon btn-light btn-sm pulse hoverwhatsapp"
                              v-show="packageValid"
                            >
                              
                                <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Write.svg-->
                                <i class="fab fa-whatsapp" style="color: white;"></i>
                                <!--end::Svg Icon-->
                            </a>
                            <a
                              href="#"
                              class="btn btn-icon btn-light btn-hover-primary btn-sm mx-3 pulse"
                              v-b-toggle.add-guest-sidebar
                              @click="getDataofGuest(data.item.id)"
                            >
                              <span class="svg-icon svg-icon-md svg-icon-primary">
                                <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Write.svg-->
                                <i class="las la-pencil-alt"></i>
                                <!--end::Svg Icon-->
                              </span>
                            </a>
                            <a
                              href="#"
                              class="btn btn-icon btn-light btn-hover-primary btn-sm pulse"
                              @click="DeleteGuest(data.item.id)"
                            >
                              <span class="svg-icon svg-icon-md svg-icon-primary">
                                <!--begin::Svg Icon | path:assets/media/svg/icons/General/Trash.svg-->
                                <inline-svg src="media/svg/icons/General/Trash.svg" />
                                <!--end::Svg Icon-->
                              </span>
                            </a>
                          </span>
                        </template>
                  
                      </b-table>
                  
                      <div class="border-0 py-5">
                        <b-row>
                          
                          <b-col
                            cols="12"
                            sm="6"
                            class="d-flex align-items-center justify-content-center justify-content-sm-start"
                          >
                            <span class="text-muted">
                                Mostrando {{ perPage }} de {{ FullCountEntries }} Invitados
                            </span>
                          </b-col>
                         
                          <b-col
                            cols="12"
                            sm="6"
                            class="d-flex align-items-center justify-content-center justify-content-sm-end"
                          >
                            <b-pagination
                              pills
                              v-model="currentPage"
                              :total-rows="totalRows"
                              :per-page="perPage"
                              :current-page="currentPage"
                              first-number
                              last-number
                              class="mb-0 mt-1 mt-sm-0"
                              prev-class="prev-item"
                              next-class="next-item"
                              @change="handleChangePage"
                            >
                              <template #prev-text>
                                <i class="fa fa-angle-left"></i>
                              </template>
                              <template #next-text>
                                <i class="fa fa-angle-right"></i>
                              </template>
                             
                            </b-pagination>
                          </b-col>
                        </b-row>
                      </div>
                    </div>
                </div>
            </b-col>
            <guest-sidebar 
              :refresh-data="getGuests"
              :get-data="getDataGuest"
            />
        </b-row>
</template>
<script>
import guestSidebar from '../ClientViews/SideBars/GuestSidebar.vue'
import { GET_GUESTS_DASHBOARD, DELETE_GUESTS, GET_GUEST_INFO, GET_GLIENT_EVENTS, GET_EVENT_PACKAGE, SAVE_EVENT_ID } from "@/core/services/store/invitation.module";
import Swal from 'sweetalert2'
export default {
    /* eslint-disable */
    components: {
      guestSidebar,
    },
    data() {
        return {
            ArrayGuests: [],
            eventSlug: '', 
            fields: [{ key: 'name', label: 'Nombre', sortable: true }, { key: 'phone', label: 'Telefono', sortable: true }, { key: 'assistance', label: 'Asistencia', sortable: true }, { key: 'escorts', label: 'Numero de Inivitados', sortable: true }, { key: 'notes', label: 'Notas personales', sortable: true }, { key: 'Actions', label: 'Acciones' }],
            perPage: 5,
            PageOptions: [5, 10, 25, 50, 100],
            actualpage: 1,
            totalRows: 100,
            currentPage: 1,
            FullCountEntries: 0,
            sortBy: 'name',
            sortDesc: false,
            sortDirection: 'asc',
            searchTerm: '',
            getDataGuest: {},
            EventsClient: [],
            Eventselected: null,
            showSelect: false,
            packageValid: true,
            confirmed: 0,
            pending: 0,
            totalguests: 0,
            unconfirmed: 0,
        }
    },
    watch: {
      perPage() {
        this.getGuests()
      },
      Eventselected(val) {
        this.storeIdEvent(val)
        this.getGuests()
      }
    },
    created() {
      this.decision()
    },
    methods: {
      decision(){
        const profile = this.$store.getters['currentUser'].user.idProfile
        if(profile == 1){
          this.showSelect = false
          this.getEventByAdmin()
        } else if (profile == 2) {
          this.showSelect = true
          this.getClientEvents()
        }
      },
      Create() {
        this.getDataGuest = {}
      },
      GuestAssitance(assistance){
        switch(assistance){
          case 0:
            return 'GuestsDenied'
            break;
          case 1:
            return 'GuestsConfirm'
            break;
          case 2:
            return 'GuestsUnConfimred'
            break;
        }
      },
      getClientEvents() {
        this.$store.dispatch(GET_GLIENT_EVENTS)
        .then((response) => {
          this.EventsClient = response.data.data
          this.Eventselected = this.EventsClient[0].id
          this.storeIdEvent(this.Eventselected)
          if(this.$store.getters['getEventId'].whatsapp == 1){
            this.packageValid = true
          } else {
            this.packageValid = false
          }
          this.getGuests()
        })
      },
      getEventByAdmin(){
        var idEvent = this.$store.getters['getEventId'].idEvent
        this.Eventselected = idEvent
        if(this.$store.getters['getEventId'].whatsapp == 1){
          this.packageValid = true
        } else {
          this.packageValid = false
        }
        this.getGuests()
      },
      getGuests() {
        this.$store.dispatch(GET_GUESTS_DASHBOARD, {
          idEvent: this.Eventselected,
          name: this.searchTerm,
          page: this.actualpage,
          rows: this.perPage,
          orderBy: this.sortBy,
          sortBy: this.sortDirection,
        })
        .then((response) => {
          console.log(response);
          const { guests , confirmed, pending, totalGuests, unconfirmed, eventSlugs } = response.data.data
          this.confirmed = confirmed
          this.pending = pending
          this.totalguests = totalGuests
          this.unconfirmed = unconfirmed
          this.eventSlug = eventSlugs[0].slug
          if(guests){
            this.ArrayGuests = guests
            this.FullCountEntries = guests[0].full_count
            this.numPages = guests[0].pages
            this.totalRows = this.FullCountEntries
            this.currentPage = this.actualpage
          } else {
            this.ArrayGuests = []
            this.FullCountEntries = 0
            this.numPages = 0
            this.totalRows = 0
            this.currentPage = 1
          }
          
        })
      },
      DeleteGuest(id) {
        Swal.fire({
          title: 'Atención',
          text: '¿Deseas borrar a este invitado de la lista?',
          icon: 'warning',
          confirmButtonText: 'Si',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
        }).then(result => {
          if (result.isConfirmed) {
            this.$store.dispatch(DELETE_GUESTS, {
              id,
              idEvent: this.Eventselected,
            })
            .then((response) => {
              Swal.fire(
                '',
                'Invitado Eliminado',
                'success'
              )
              this.getGuests()
            })
          }
        })
      },
      storeIdEvent(id){
        this.$store.dispatch(GET_EVENT_PACKAGE,{
          idEvent: id,
        }).then((response) => {
          this.$store.dispatch(SAVE_EVENT_ID, {
            paramsEvent: response.data.data,
          }).then(() => {
            if(this.$store.getters['getEventId'].whatsapp == 1){
              this.packageValid = true
            } else {
              this.packageValid = false
            }
          })
        })
      },
      getDataofGuest(id) {
        this.$store.dispatch(GET_GUEST_INFO, {
          id,
          idEvent: this.Eventselected,
        })
        .then((response) => {
          response.data.data.id = id
          response.data.data.idEvent = this.Eventselected
          this.getDataGuest = response.data.data
        })
      },
      handleSearch(searching) {
        this.searchTerm = searching
        this.actualpage = 1
        this.totalRows = 1
        this.getGuests()
      },
      handleChangePage(page) {
        this.actualpage = page
        this.getGuests()
      },
      onSortChange(params) {
        this.sortBy = params.sortBy
        this.sortDesc = params.sortDesc
        const direction = params.sortDesc === true ? 'desc' : 'asc'
        this.sortDirection = direction
        this.getGuests()
      },
      NavigateAccessLevelProfile(id) {
        this.$store.commit('access-level/setIdAccessLevel', id)
        this.$router.push({ name: 'access-level-profile' })
      },
    },
}
</script>

<style scoped>
.GuestsConfirm{
  color: #52DD26 !important; 
  background-color: rgba(82, 221, 38, 0.1) !important;
}

.GuestsDenied{
  color: #DD2C26 !important; 
  background-color: rgba(221, 44, 38, 0.1) !important;
}

.GuestsUnConfimred{
  color: hsl(0, 0%, 56%) !important; 
  background-color: rgba(143, 143, 143, 0.1) !important;
}

.hoverwhatsapp:hover{
  background-color: #25D366 !important;
  color: white !important;
}
</style>