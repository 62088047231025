<template>
    <b-sidebar
      id="add-guest-sidebar"
      shadow
      right
      bg-variant="white"
      backdrop
      width="400px"
      @hidden="cleanClientSidebar"
    >

    <template #default="{ hide }">
      <div style="padding: 35px;">
        <span class="text-dark-75 font-weight-bolder text-hover-primary" style="font-size: 15px;">
          {{ Object.keys(getData).length === 0 ? 'Agregar Invitado' : 'Editar Invitado' }}
        </span>

        <b-row class="mt-5">
          <b-col sm="12">
            <b-form-group
              label="Nombre"
              label-for="Nombre"
            >
             <b-form-input
               v-model="FirstNameGuest"
               type="text"
               class="form-control-merge"
               placeholder="Nombre"
             />
            </b-form-group>
          </b-col>
          <b-col sm="12">
            <b-form-group
              label="Apellido"
              label-for="Apellido"
            >
             <b-form-input
               v-model="LastNameGuest"
               type="text"
               class="form-control-merge"
               placeholder="Apellido"
             />
            </b-form-group>
          </b-col>
         <b-col sm="12">
            <b-form-group
              label="Telefono"
              label-for="Telefono"
            >
            <b-input-group
                class="mb-5"
              >
                <b-input-group-prepend>
                  <b-form-select
                    v-model="Lada"
                    :options="OptionsLada"
                   >
                  </b-form-select>
                </b-input-group-prepend>
                <b-form-input
                  v-model="PhoneGuest"
                  type="text"
                  class="form-control-merge"
                  placeholder="--- --- --- -"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col sm="12">
            <b-form-group
              label="¿Asisitira?"
              label-for="¿Asisitira?"
            >
                <b-form-radio-group
                  id="radio-group-1"
                  v-model="ConfirmationSelected"
                  :options="optionsConfirmation"
                  name="radio-options"
                ></b-form-radio-group>
            </b-form-group>
          </b-col>
          <b-col sm="12">
            <b-form-group
              label="Acompañantes"
              label-for="Acompañantes"
            >
                <b-form-spinbutton id="demo-sb" size="lg" v-model="EscortCompanionsGuest" min="0" :max="EscorAllowed"></b-form-spinbutton>
            </b-form-group>
          </b-col>
          <b-col sm="12" v-show="packageValid">
            <b-form-group
              label="Mensaje Personal"
              label-for="Mensaje Personal"
            >
             <b-form-textarea
               v-model="PersonalMessage"
               id="textarea-default"
               placeholder="Mensaje Especial"
               rows="3"
             />
            </b-form-group>
          </b-col>

          <b-col v-if="notes" sm="12">
            <b-form-group
              label="Nota o edicatoria de mi Invitado"
              label-for="Nota o edicatoria de mi Invitado"
            >
                <div style="font-style: oblique; font-weight: 100; opacity: 0.8;"> " {{ notes }} "</div>
            </b-form-group>
          </b-col>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button 
              @click="CreateGuest()"
            >
              {{ Object.keys(getData).length === 0 ? 'Agregar' : 'Editar' }}
            </b-button>
            <b-button
              ref="closeSideBarGuest"
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              @click="hide"
            >
              Cancelar
            </b-button>
          </div>
        </b-row>
     </div>
    </template>
    </b-sidebar>
</template>

<script src="assets/plugins/global/plugins.bundle.js"></script>
<script>

// import clientService from './src/core/services/client/clientService'
import { CREATE_GUEST, EDIT_GUEST } from "@/core/services/store/invitation.module";
import Swal from 'sweetalert2'
 
export default {
  components: {
  },
  data() {
    return{
      FirstNameGuest: '',
      LastNameGuest: '',
      PhoneGuest: '',
      Lada: '52',
      OptionsLada: [
        { value: '52', text: '+52' },
        { value: '1', text: '+1' },
      ],
      ConfirmationSelected: '1',
      optionsConfirmation: [
        { text: 'Si', value: '1' },
        { text: 'No', value: '0' },
        { text: 'Sin Confirmar', value: '2' },
      ],
      EscortCompanionsGuest: 0,
      notes: '',
      PersonalMessage: '',
      idGuest: 0,
      packageValid: false,
      EscorAllowed: 0,
    }
  },
  props: {
    refreshData: {
      type: Function,
      required: true,
    },
    getData: {
      type: Object,
      required: true,
    },
  },
  watch: {
    getData(val) {
      console.log(val);
      if(this.$store.getters['getEventId'].whatsapp == 1){
        this.packageValid = true
      } else {
        this.packageValid = false
      }
      this.EscorAllowed = this.$store.getters['getEventId'].escortAllowed
      if (Object.keys(val).length !== 0) {
        this.FirstNameGuest = val.firstname
        this.LastNameGuest = val.lastname
        this.PhoneGuest = val.phone
        this.ConfirmationSelected = val.assistance
        this.idGuest = val.id
        this.EscortCompanionsGuest = val.escorts
        this.PersonalMessage = val.personal_message
        this.notes = val.notes
        this.Lada = val.lada
      } else {
        this.FirstNameGuest = ''
        this.LastNameGuest = ''
        this.PhoneGuest = ''
        this.ConfirmationSelected = 2
        this.idGuest = 0
        this.EscortCompanionsGuest = 0
        this.PersonalMessage = ''
        this.notes = null
        this.Lada = '52'
      }
    },
  },
  methods: {
    validateInputs() {
      let Text = ''
      if (this.FirstNameGuest === '') {
        Text += '- Nombres Faltan<br>'
      } else {
        Text += ''
      }
      if (this.LastNameGuest === '') {
        Text += '- Apellidos Faltan<br>'
      } else {
        Text += ''
      }
      if (this.PhoneGuest === '') {
        Text += '- Telefono Falta<br>'
      } else {
        Text += ''
      }
      if (Text !== '') {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          html: `<div class="lbedo">${Text}</div>`,
          showConfirmButton: false,
        })
        return false
      } else {
        return true
      }
    },
    CreateGuest(){
      if(this.validateInputs()){
        if(Object.keys(this.getData).length === 0){
          var idEvent = this.$store.getters['getEventId'].idEvent
          this.$store.dispatch(CREATE_GUEST, {
            idEvent: idEvent,
            firstName: this.FirstNameGuest,
            lastName: this.LastNameGuest,
            phone: this.PhoneGuest,
            assistance: this.ConfirmationSelected,
            escorts: this.EscortCompanionsGuest,
            personalMessage: this.PersonalMessage,
            lada: this.Lada,
          })
          .then((response) => {
            this.$refs.closeSideBarGuest.click()
            this.refreshData()
          })
        } else {
          var idEvent = this.$store.getters['getEventId'].idEvent
          this.$store.dispatch(EDIT_GUEST, {
            idEvent: idEvent,
            id: this.idGuest,
            firstName: this.FirstNameGuest,
            lastName: this.LastNameGuest,
            phone: this.PhoneGuest,
            assistance: this.ConfirmationSelected,
            escorts: this.EscortCompanionsGuest,
            personalMessage: this.PersonalMessage,
            lada: this.Lada,
            notes: this.notes
          })
          .then(() => {
            this.$refs.closeSideBarGuest.click()
            this.refreshData()
          })
        }
      }
    },
    cleanClientSidebar(){
      this.FirstNameClient = ''
      this.LastNameClient = ''
      this.PhoneClient = ''
      this.Email = ''
      this.idClient = 0
      this.Password = ''
    }
  }
}
</script>